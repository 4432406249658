import { setMultiTenancyConfigData, setAffiliateLoading } from "../redux/modules/multiTenancyConfig";
import { useEffect, useCallback, useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import config from "../clientConfig";
import { useNavigate } from "react-router-dom";

const StyleConfiguration: any = (props: any) => {
  const widgetConfig = props;
  const [error, setError] = useState<string>("");
  const [affiliateConfig, setAffiliateConfig] = useState<any>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const VerifySuperAffiliateUser = async () => {          
      dispatch(setAffiliateLoading(true));
      try {
        const response = await fetch(`${config.apiUrl}auth/VerifySuperAffiliateUser`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'accept': '/',
            'frameKey': widgetConfig?.widgetConfig?.frameKey ? widgetConfig?.widgetConfig?.frameKey : config.frameKey,
          },
        });

        const data = await response.json();
        if(data.responsetype == 'error') {
          navigate('/notfound');
          dispatch(setAffiliateLoading(false));
        }else {
          localStorage.setItem("widgetToken", data?.data?.token);
          localStorage.setItem("affiliateId", data?.data?.affiliateId);
          fetchAffiliateConfig();
        }
      } catch (error) {
        // Check if the error is an instance of Error and handle it accordingly
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    const fetchAffiliateConfig = async () => {
      try {
        const response = await fetch(`${config.apiUrl}Affiliate/GetAffiliateConfiguration`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'accept': '/',
            'apiKey': config.apiKey,
            'Frame-Authorization': localStorage.getItem("widgetToken"),
            'affiliateId': localStorage.getItem("affiliateId"),
          },
        });

        const data = await response.json();
        if(data.responsetype == 'error') {
          navigate('/notfound');
          dispatch(setAffiliateLoading(false));
        }else {
          dispatch(setMultiTenancyConfigData(data));
          setAffiliateConfig(data);
          dispatch(setAffiliateLoading(false));
          if (!data.isActive) {
            navigate('/domain-deactivated');
          }
        }

       /*  if (!response.ok) {
          throw new Error('Network response was not ok');
        } */
      } catch (error) {
        // Check if the error is an instance of Error and handle it accordingly
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    VerifySuperAffiliateUser();
  }, []); // Empty dependency array means this effect runs once on mount

  const primaryClr = affiliateConfig?.primaryColourCode;
  const secondaryClr = affiliateConfig?.secondaryColourCode;
  const sideBarClr = affiliateConfig?.defaultColourCode;

  const styleConfiguration = `
    :root {
      --dynamicPrimaryColor: ${primaryClr};
      --dynamicSecondaryColor: ${secondaryClr};
      --dynamicNavBarColor: ${sideBarClr};
    }
  `;
  return styleConfiguration;
};

export default StyleConfiguration;