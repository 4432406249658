import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { orderBy } from "lodash";

import api from "../../utils/apiClient";
import groupBy from "../../utils/groupBy";

export interface StaticState {
	staticLoader: boolean;
	LoaderTxt: string;
	countryLoader: boolean;
	regCountries: any;
	loading: any;
	countryGroupIso3: any;
	homeCountryGroupIso3: any;
	occupations: any;
	providers: any;
	docTypes: any;
	topUpPacks: any;
	provinceStates: any;
}

const initialState: StaticState = {
	staticLoader: false,
	LoaderTxt: "",
	countryLoader: false,
	regCountries: {},
	loading: {
		showCorridorLoader: false,
		showOccupationLoader: false,
		documentTypeLoader: false,
		packsLoader: false,
		provinceStateLoader: false,
	},
	countryGroupIso3: {},
	homeCountryGroupIso3: {},
	occupations: [],
	providers: null,
	docTypes: [],
	topUpPacks: [],
	provinceStates: {},
};

export const getCountries = createAsyncThunk(
	"Static/Countries",
	async (isRegistered: boolean) => {
		const URL = isRegistered
			? "Static/Countries?isRegister=true"
			: "Static/Countries";
		const response = await api.get(URL);
		return response;
	}
);

export const getCountriesByServiceCode = createAsyncThunk(
	"Static/Countries",
	async (data: any) => {
		const response = await api.get(
			`Static/Countries?isRegister=false&serviceCode=${data}`
		);
		return response;
	}
);

export const getRelationships = createAsyncThunk(
	"Static/Relationships",
	async () => {
		const response = await api.get("Static/Relationships");
		return response.data;
	}
);

export const getReasons = createAsyncThunk(
	"Static/Reasons",
	async (data: any) => {
		const response = await api.get(
			`Static/Reasons?countryiso3=${data.countryiso3}&currencyCode=${data.currencyCode}&legalEntityType=${data.legalEntityType}`
		);
		return response.data;
	}
);

export const getProvinceState = createAsyncThunk(
	"Static/ProvinceState",
	async (data: any) => {
	  const response = await api.get(
		`Static/ProvinceState?countryiso3=${data.countryiso3}`
	  );
	  return response.data;
	}
  );

export const getDocumentTypes = createAsyncThunk(
	"Static/DocumentTypes",
	async () => {
		const response = await api.get("Static/DocumentTypes");
		return response.data;
	}
);

export const country = createAsyncThunk("Static/Countries", async () => {
	const response = await api.get("Static/Countries");
	return response;
});

export const getHomeCorridors = createAsyncThunk(
	"Static/Corridors",
	async () => {
		const response = await api.get("Static/Corridors");
		const res = response.data;
		return res;
	}
);

export const getCorridors = createAsyncThunk("Static/Corridors", async () => {
	const response = await api.get("Static/Corridors");

	const res = groupBy(response.data, "fromCountryISO3");
	return res;
});

export const getOccupations = createAsyncThunk(
	"Static/Occupations",
	async () => {
		const response = await api.get("Static/Occupations");
		return response.data;
	}
);

export const providers = createAsyncThunk(
	"Static/Providers",
	async (data: any) => {
		const response = await api.get(
			`Static/Providers?countryiso3=${data.selectContryCode}&CurrencyCode=${data.currency}&servicecode=${data.shortcode}&collectionMode=${data.collectionMode}`
		);

		return response;
	}
);
export const providersMobileTopUp = createAsyncThunk(
	"Static/Providers",
	async (data: any) => {
		const response = await api.get(
			`Static/Providers?countryiso3=${data.selectContryCode}&CurrencyCode=${data.currency}&servicecode=${data.shortcode}`
		);
		return response;
	}
);

export const getMobileTopUpPacks = createAsyncThunk(
	"Static/ProviderItems",
	async (data: any) => {
		const response = await api.get(
			`Static/ProviderItems?ProviderId=${data.ProviderId}`
		);
		return response;
	}
);
export const staticSlice = createSlice({
	name: "static",
	initialState,
	reducers: {
		resetProviders: (state, action) => {
			state.providers = null;
		},
		setCorridors: (state, action) => {
			state.loading.showCorridorLoader = false;
			state.countryGroupIso3 = action.payload;
		},
		setHomeCorridors: (state, action) => {
			state.loading.showCorridorLoader = false;
			state.homeCountryGroupIso3 = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCountriesByServiceCode.pending, (state) => {
				state.staticLoader = true;
			})
			.addCase(getCountriesByServiceCode.rejected, (state) => {
				state.staticLoader = false;
			})
			.addCase(getCountriesByServiceCode.fulfilled, (state, action) => {
				state.staticLoader = false;
				state.regCountries = action.payload.data;
			})

			.addCase(providers.pending, (state) => {
				state.staticLoader = true;
			})
			.addCase(providers.rejected, (state) => {
				state.staticLoader = false;
			})
			.addCase(providers.fulfilled, (state, action) => {
				state.staticLoader = false;
				state.providers = action.payload.data;
			})
			.addCase(getMobileTopUpPacks.pending, (state) => {
				state.loading.packsLoader = true;
			})
			.addCase(getMobileTopUpPacks.rejected, (state) => {
				state.loading.packsLoader = false;
			})
			.addCase(getMobileTopUpPacks.fulfilled, (state, action) => {
				state.loading.packsLoader = false;
				state.topUpPacks = action.payload;
			})
			.addCase(getDocumentTypes.pending, (state) => {
				state.loading.documentTypeLoader = true;
			})
			.addCase(getDocumentTypes.rejected, (state) => {
				state.loading.documentTypeLoader = false;
			})
			.addCase(getDocumentTypes.fulfilled, (state, action) => {
				state.loading.documentTypeLoader = false;
				state.docTypes = action.payload;
			})
			.addCase(getCorridors.pending, (state) => {
				state.loading.showCorridorLoader = true;
			})
			.addCase(getCorridors.rejected, (state) => {
				state.loading.showCorridorLoader = false;
			})
			.addCase(getCorridors.fulfilled, (state, action) => {
				state.loading.showCorridorLoader = false;
				state.countryGroupIso3 = action.payload;
			})
			.addCase(getOccupations.pending, (state) => {
				state.loading.showOccupationLoader = true;
			})
			.addCase(getOccupations.rejected, (state) => {
				state.loading.showOccupationLoader = false;
			})
			.addCase(getOccupations.fulfilled, (state, action) => {
				state.loading.showOccupationLoader = false;
				state.occupations = action.payload;
				let sortedList = orderBy(state.occupations, [(o) => o.name]);
				state.occupations = sortedList;
			})
			.addCase(getProvinceState.pending, (state) => {
				state.loading.provinceStateLoader = true;
			  })
			  .addCase(getProvinceState.rejected, (state) => {
				state.loading.provinceStateLoader = false;
			  })
			  .addCase(getProvinceState.fulfilled, (state, action) => {
				state.loading.provinceStateLoader = false;
				state.provinceStates = action.payload;
			  });
	},
});

export const { resetProviders, setCorridors, setHomeCorridors } = staticSlice.actions;

export default staticSlice.reducer;
