import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../utils/apiClient";

export interface RateState {
	rateValues: any;
	loading: any;
	validateRateValue: any;
	rateEndTime: any;
	isTimeout: any;
	isComplete: any;
	error?: any;
	checkTimer?: any;
}

const initialState: RateState = {
	rateValues: {},
	loading: { showLoader: false, validateRateLoading: false },
	validateRateValue: {},
	rateEndTime: null,
	isTimeout: false,
	isComplete: false,
	checkTimer: true,
};

export const validateRate = createAsyncThunk(
	"Rate/validateRate",
	async (data: any) => {
		const response = await api.post("Rate/validateRate", { data });
		return response.data;
	}
);

export const getRate = createAsyncThunk("Rate/GetRate", async (data: any) => {
	const response = await api.post("Rate/GetRate", { data });
	return response.data;
});

export const rateSlice = createSlice({
	name: "Rate",
	initialState,
	reducers: {
		setTimerValue: (state, action) => {
			state.isTimeout = true;
		},
		setIsComplete: (state, action) => {
			state.isComplete = true;
		},
		resetRateData: (state, action) => {
			state.validateRateValue = {};
			state.rateValues = {};
		},
		resetValidateRateData: (state, action) => {
			state.validateRateValue = {};
		},
		setRate: (state, action) => {
			state.loading.showLoader = false;
			state.rateValues = action.payload;
		},
		setResetTimerValue: (state, action) => {
			state.isTimeout = Date.now() >=  action.payload ? true : false;
			state.rateEndTime = action.payload;
			state.error = undefined;
		},
		setCheckTimer: (state, action) => {
			state.checkTimer = action.payload;
			// state.isTimeout = Date.now() >=  action.payload ? true : false;
			// state.rateEndTime = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(validateRate.pending, (state) => {
				state.loading.validateRateLoading = true;
				state.rateEndTime = null;
				state.isTimeout = false;
			})
			.addCase(validateRate.rejected, (state) => {
				state.loading.validateRateLoading = false;
				state.rateEndTime = null;
				state.isTimeout = false;
			})
			.addCase(validateRate.fulfilled, (state, action) => {
				state.loading.validateRateLoading = false;
				state.validateRateValue = action.payload;
				let getCheckTimer = state.checkTimer;
				if(getCheckTimer){
					state.isTimeout = Date.now() >=  Date.now() + 600000 ? true : false;
					state.rateEndTime = Date.now() + 600000;
				}
				state.error = undefined;
			})
			.addCase(getRate.pending, (state) => {
				state.loading.showLoader = true;
			})
			.addCase(getRate.rejected, (state, action) => {
				state.loading.showLoader = false;
				state.error = "Minimum send amount is: 1.03";
				state.rateValues = null;
			})
			.addCase(getRate.fulfilled, (state, action) => {
				state.loading.showLoader = false;
				state.rateValues = action.payload;
				state.error = undefined;
			});
	},
});

export const {
	resetRateData,
	resetValidateRateData,
	setTimerValue,
	setIsComplete,
	setRate,
	setResetTimerValue,
	setCheckTimer
} = rateSlice.actions;

export default rateSlice.reducer;
